import axios from "axios"
export async function descargarestudio(){
    return new Promise((Pres,Prej)=>{
        let ID_est = JSON.parse(window.sessionStorage.getItem("EstudioVisor"))
        let configuracion = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral"))
        axios.get(`${configuracion.ServidorDicom}/DescargaEstudio/${ID_est.EST_UID}`, {
            onDownloadProgress: function(progressEvent) {
                let avance = progressEvent.loaded*100/progressEvent.total
              document.getElementById("DownloadButton").querySelector(".Valor").innerHTML = avance.toFixed(0)
            },
            responseType: 'blob',
          })
        .then(res=>{
            Pres({noError:true,valor:res.data})
        })
        .catch((e)=>{
                console.log(e)
                Pres({noError:false,valor:null})
        })
    })
}