import React from 'react'

function Caducado() {
    return (
        <div className='Caducado'>
            <div className='Logocaducado'></div>
            <div className='mensaje'>
                El enlace ha caducado por favor pogase en contacto con Medicaltec SRL al numero {<a href="https://wa.me/59170866119" >+59170866119</a>}
            </div>
        </div>
    )
}

export default Caducado
