import React, { useEffect, useState } from 'react'

import Share from "./Share"
import Upload from "./Uploadfile"

import Consulta from "../ClasesFunsiones/consultas"

import Iconos from "../ClasesFunsiones/Iconos"

import "./Dialog.scss";


function Dialog(props) {
    let clase = props.activo.visible?"activo":""
    return (
        <div id="DialogN" className={clase}>
            <Velo onClick={props.CerrarDialog}/>
            <Box {...props}/>
        </div>
    )
}

export default Dialog

const Velo = (props)=>{
    return(
        <div className="Velo activo" onClick={props.onClick}></div>
    )
}

const Box = (props)=>{
    let COMP
    switch (props.activo.componente) {
        case 0:
            COMP = <Share {...props}/>
            break;
        case 1:
            COMP = <Upload {...props}/>
        break;
        case 2:
            COMP = <Agenda {...props}/>
        break;
        default:
            COMP = <Share {...props}/>
            break;
    }
    return(
        <div className="Box">
            <div className="CloseDialog" onClick={props.CerrarDialog}>X</div>
            <div className="Contenido">
                {COMP}
            </div>
        </div>
    )
}

const Agenda = (props)=>{
    const [Contactos, setContactos] = useState({listo:false,lista:[]})
    let fetchcontrolador = new AbortController()
    let tarjetas = []
    for (let index = 0; index < Contactos.lista.length; index++) {
        const element = Contactos.lista[index];
        tarjetas.push(<TarjetaContacto key={index} contacto={element} {...props}/>)
    }
    useEffect(() => {
        Consulta.getcontactos(fetchcontrolador.signal)
        .then((res)=>{
            console.log(res)
            setContactos({listo:true,lista:res})
        })
        return () => {
            fetchcontrolador.abort()
        }
    }, [])
    if(!Contactos.listo){
        return(
            <div className="Agenda">
                Cargando......
            </div>
        )
    }
    return(
        <div className="Agenda">
            {tarjetas}
        </div>
    )
}
const TarjetaContacto = (props)=>{
    let compartirWP = ()=>{
        props.paciente.ShareWPcontacto(props.contacto)
    }
    let compartirmail = ()=>{
        let data = props.paciente.ShareMailcontacto(props.contacto)
        Consulta.sharecorreo(data)
        .then((res)=>{
            if(res.res){
              alert("Correo enviado")
            }else{
              alert("Envio Fallido")
            }
        })
    }
    return(
        <div className="TarjetaContacto">
            <div className="TarjetaContacto1">
                <div className="TarjetaContacto11">{props.contacto.nombre}</div>
                <div className="TarjetaContacto12">{props.contacto.area}</div>
            </div>
            <div className="TarjetaContacto2">
                <ShareM onClick={compartirWP} icon="WP"/>
                <ShareM onClick={compartirmail} icon="mail"/>
            </div>
        </div>
    )
}

const ShareM = (props)=>{
    return(
        <div className="ShareM" onClick={props.onClick}>
            <Iconos icon={props.icon}/>
        </div>
    )
}