import cornerstone from 'cornerstone-core'
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import dicomParser from 'dicom-parser'
import cornerstoneTools from 'cornerstone-tools'
import Hammer from 'hammerjs'
import cornerstoneMath from 'cornerstone-math'

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();
cornerstoneTools.toolStyle.setToolWidth(2);
cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');
cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');

const WwwcTool = cornerstoneTools.WwwcTool;
const LengthTool = cornerstoneTools.LengthTool;
const MagnifyTool = cornerstoneTools.MagnifyTool;
const PanTool = cornerstoneTools.PanTool;
const RotateTool = cornerstoneTools.RotateTool;
const ZoomTouchPinchTool = cornerstoneTools.ZoomTouchPinchTool;
const PanMultiTouchTool = cornerstoneTools.PanMultiTouchTool;
const ZoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
const AngleTool = cornerstoneTools.AngleTool;
const CobbAngleTool = cornerstoneTools.CobbAngleTool;



export function verAlerta(mensaje,tipo){
    let alerta = document.getElementById("Alerta")
    alerta.innerHTML=mensaje
    alerta.classList.add(tipo)
    setTimeout(() => {
        alerta.innerHTML=""
        alerta.classList.remove(tipo)
    }, 2000);
}
export async function CargarImagenes(imagenes,setPrimeraImagen,setListaImages,señal){
    let Img_IDs = imagenes.FILES.map((UID)=>{return UID.INS_UID})
    let configuracion = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral"))
    let Arr = []
    for (let i = 0; i < Img_IDs.length; i++) {
        const element = Img_IDs[i];
        let req = await fetch(`${configuracion.ServidorDicom}/getfiles/${element}`,{señal})
        let res = await req.blob()
        if(señal.aborted){
            break;
        }
        let imgID = cornerstoneWADOImageLoader.wadouri.fileManager.add(res)
        Arr.push(imgID)
        setListaImages([...Arr])
        if(i===0){
            setPrimeraImagen(true)
        }
    }
}

export async function verPrimeraImagen(ListaImages,ImgNav,VistaIndex){
    let element = document.getElementsByClassName("Dicom")[VistaIndex]
    cornerstone.disable(element)
    cornerstone.enable(element)
    cornerstoneTools.addTool(WwwcTool)
    cornerstoneTools.addTool(LengthTool)
    cornerstoneTools.addTool(ZoomTouchPinchTool)
    cornerstoneTools.addTool(PanMultiTouchTool)
    cornerstoneTools.addTool(MagnifyTool)
    cornerstoneTools.addTool(PanTool)
    cornerstoneTools.addTool(RotateTool)
    cornerstoneTools.addTool(ZoomMouseWheelTool)
    cornerstoneTools.addTool(AngleTool)
    cornerstoneTools.addTool(CobbAngleTool)
    cornerstoneTools.setToolActive('ZoomTouchPinch', { })
    cornerstoneTools.setToolActive('PanMultiTouch', { })
    let imagen = await cornerstone.loadImage(ListaImages[ImgNav])
    cornerstone.displayImage(element,imagen);
    window.sessionStorage.setItem("DefaultViewPort",JSON.stringify(cornerstone.getViewport(element)))
}


export async function verimagen(ListaImages,ImgNav,VistaIndex){
    let element = document.getElementsByClassName("Dicom")[VistaIndex]
    let imagen = await cornerstone.loadImage(ListaImages[ImgNav])
    cornerstone.displayImage(element,imagen);
}

export function activarHerramientas(herramienta,herramientaD){
    let OS = JSON.parse(window.localStorage.getItem("OsDevice"))
    if(OS.mobil){
        //cornerstoneTools.setToolDisabled(herramienta.previa, { mouseButtonMask: 1 })
        cornerstoneTools.setToolActive(herramienta.actual, { mouseButtonMask: 1 })
    }else{
        cornerstoneTools.setToolDisabled(herramientaD[0].previa, { mouseButtonMask: 1 })
        cornerstoneTools.setToolActive(herramientaD[0].actual, { mouseButtonMask: 1 })
        cornerstoneTools.setToolDisabled(herramientaD[1].previa, { })
        cornerstoneTools.setToolActive(herramientaD[1].actual, { })
        //cornerstoneTools.setToolDisabled(herramientaD[2].previa, { mouseButtonMask: 2 })
        cornerstoneTools.setToolActive(herramientaD[2].actual, { mouseButtonMask: 2 })
    }
}

export function incNav(ImgNav,ListaImages,imagenes,setImgNav){
    if (ImgNav + 1 < ListaImages.length) {
      setImgNav(ImgNav + 1);
    } else {
      if (ListaImages.length === imagenes.FILES.length) {
        verAlerta("Ultima Imagen", "Peligro");
      } else {
        verAlerta("Descargando Imagenes", "Advertencia");
      }
    }
  }

export function decNav(ImgNav,setImgNav){
    if (ImgNav - 1 >= 0) {
      setImgNav(ImgNav - 1);
    } else {
      verAlerta("Primera Imagen", "Peligro");
    }
  }