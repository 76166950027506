import React from 'react'
import BuscarIcon from "../assets/buscar.png"

function Cabeza(props) {
    return (
        <div className="CabezaMain">
            <div className="Buscador">
                <div className="contenedor">
                    <div className="icono">
                        <img src={BuscarIcon} alt="I"/>
                    </div>
                    <div className="Continput">
                        <input type="text" placeholder="Buscar" onChange={(e)=>{props.filtrar(e.target.value)}} value={props.filtro}/>
                    </div>
                </div>
            </div>
            <div className="Logo"></div>
        </div>
    )
}

export default Cabeza
