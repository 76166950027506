export async function _iniciar(){
    let reqconf = await fetch('/configuracion.json')
    let configuraciones = await reqconf.json()
    window.localStorage.setItem('ConfiguracionGeneral',JSON.stringify(configuraciones))
    let token = localStorage.getItem('MedId')
    if(token === null){
        console.log('no hay token')
        return({configuraciones:configuraciones,aut:{verificado:false}})
    }else{
        try{
            let authreq = await fetch(`${configuraciones.ServidorDicom}/autenticacion/${token}`)
            let aut = await authreq.json()
            return({configuraciones:configuraciones,aut:aut})
        }
        catch(e){
            throw e
        }
    }
}

export function mobil(){
    let toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    for(let i = 0 ; i<= toMatch.length-1 ; i++){
        if(navigator.userAgent.match(toMatch[i]) !== null){
            return {mobil:true,os:toMatch[i].source}
        }
    }
    return {mobil:false,os:null}
}