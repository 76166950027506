import React,{useState,useEffect} from 'react';
import './App.scss';
import {BrowserRouter,Route,Redirect} from "react-router-dom"
import * as funciones from './appfunciones'

import ConsoltasApp from "./ConsultasApp"

import Login from './componentes/Login2/Login'
import Visor from './componentes/NuevoVisor/Visor'
import Main from './componentes/Main3/Main'
import Externo from './componentes/Externo/Externo';
import Test from "./componentes/test/test"

import wifioff from "./wifi_off-black-48dp/2x/baseline_wifi_off_black_48dp.png"

export const ContextoGeneral = React.createContext('')
function App() {
  const [iniciado, setiniciado] = useState({iniciado:false,autenticado:null})
  const [usuario, setusuario] = useState(null)
  const [configuraciones, setconfiguraciones] = useState(null)
  const [ErrorCon, setErrorCon] = useState(false)
  let mobil = funciones.mobil()
  window.localStorage.setItem("OsDevice",JSON.stringify(mobil))
  useEffect(()=>{
    window.sessionStorage.removeItem('filtrosmain')
    document.getElementById('InicioApp').style.display = "none"
    funciones._iniciar()
    .then((res)=>{
      if(res.aut.verificado){
        setusuario({...res.aut.usuario})
        setconfiguraciones({...res.configuraciones})
        setiniciado({iniciado:true,autenticado:true})
      }else{
        setusuario(null)
        setconfiguraciones({...res.configuraciones})
        setiniciado({iniciado:true,autenticado:false})
      }
    })
    .catch(e=>{
      console.log(e)
      setErrorCon(true)
      setiniciado({iniciado:true,autenticado:false})
    })
  },[])

  if(!iniciado.iniciado){
    return(
      <div style={{'color':'red'}}>INICIANDO......</div>
      )
    }
    if(ErrorCon){
      return(
        <ErrorConCom/>
      )
    }
  let valor = {
    iniciado:iniciado,
    setiniciado:setiniciado,
    usuario:usuario,
    setusuario:setusuario,
    configuraciones:configuraciones,
    setconfiguraciones:setconfiguraciones,
    Colores:{
      primario:"#d1551b",
      secundario:"#009090",
      tercero:"#ffc78d"
    }

  }

  ConsoltasApp.Asignarurl()

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      iniciado.autenticado === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
  return (
    <ContextoGeneral.Provider value={valor}>
      <div className="App">
        <BrowserRouter>
          <Route path="/" exact component={Login}/>
          <Route path="/login" component={Login}/>
          <PrivateRoute path="/main" component={Main}/>
          <PrivateRoute path="/visor/:id" component={Visor}/>
          <Route path="/externo/:token" component={Externo}/>
          <Route path="//externo/:token" component={Ext2}/>
          <Route path="/test" component={Main}/>
        </BrowserRouter>
      </div>
    </ContextoGeneral.Provider>
  );
}

//////eliminar en siguiente update///////////
export default App;
const ErrorConCom = ()=>{
  return(
    <div style={{ height:"400px", display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
      <div>
        <img src={wifioff} alt="" width="200px"/>
      </div>
      <div style={{fontSize:"30px"}}>Verifique si conexion de red y actualice la APP</div>
    </div>
  )
}
const Ext2 = (props)=>{
  props.history.push("/externo/"+props.match.params.token)
  return(
    <div>
      {"<========================>"}
    </div>
  )
}
