export class HerramientasDicomD{
    constructor(H){
        this.previa=H
        this.actual=H
    }
    cambiarHerramienta=(NH)=>{
        this.previa=this.actual
        this.actual=NH
        return this
    }
}

export class HerramientasDicomM{
    constructor(H){
        this.previa=H
        this.actual=H
    }
    cambiarHerramienta=(H,NH)=>{
        this.previa=H.actual
        this.actual=NH
        return this
    }
}